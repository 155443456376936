import React from 'react';
// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker
// } from '@material-ui/pickers';
const LayoutPedidos = () => {
  return <h1>Pantalla pedidos</h1>;
};

export default LayoutPedidos;
