import React, { useEffect, useRef, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { useContext } from 'react';
import { FacturaContext } from '../../../context/FacturaContext';
import { makeStyles } from '@material-ui/core/styles';

import alertify from 'alertifyjs';

import './listadoProductos.css';
// import beepSound from './beep.mp3';
import beepSound from './beep.mp3';

// Carga Asincrona.
import { FixedSizeList } from 'react-window';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(0, 3)
  },
  paper: {
    maxWidth: '100%',
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(1)
  }
}));
// setProductosFactura([...productosFactura, newProduct]);
const ProductosTabla = ({ productos }) => {
  const productosConStock = productos.filter((producto) => producto.stock > 0);
  const {
    buscarProductoCodigoBarras,
    productosFactura,
    setProductosFactura,
    agregarProductoFactura
  } = useContext(FacturaContext);
  // Referencia para almacenar el código de barras escaneado
  const scannedDataRef = useRef('');
  const [audio] = useState(new Audio(beepSound));
  // Función para manejar el evento de teclado
  const handleKeyPress = async (event) => {
    if (event.key === 'Enter') {
      // Al presionar Enter, procesar el código de barras
      const scannedData = scannedDataRef.current;
      if (scannedData) {
        try {
          const productoEncontrado = await productos.find(
            (producto) => producto.codigo_barra === scannedData
          );
          // audio.play();
          // alert(JSON.stringify(productoEncontrado));
          const newProduct = {
            cantidad: 1,
            id: productoEncontrado.id,
            nombre: productoEncontrado.nombre,
            precio_publico: productoEncontrado.precio_publico,
            precio_tecnico: productoEncontrado.precio_tecnico,
            precio_distribuidor: productoEncontrado.precio_distribuidor,
            total: 0, //; obtienePrecioBruto(totalParcial, producto),
            totalBruto: 0,
            tipoPrecio: 0,
            stock: productoEncontrado.stock,
            gravaIva: productoEncontrado?.gravaIva,
            pocentaje: productoEncontrado?.pocentaje
          };

          const producto = await agregarProductoFactura(newProduct);

          // Aquí puedes hacer algo con el producto, como mostrarlo en la interfaz de usuario.
        } catch (error) {
          console.error('Error al buscar el producto:', error);

          alertify.error('No se encontró el producto', 2);
        } finally {
          // Limpiar el código de barras después de procesarlo
          scannedDataRef.current = '';
        }
      }
    } else {
      // Concatenar el texto escaneado al valor actual antes del "Enter"
      scannedDataRef.current += event.key;
    }
  };

  useEffect(() => {
    // Agregar el event listener para las teclas cuando el componente se monta
    window.addEventListener('keydown', handleKeyPress);

    // Limpiar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [productosFactura]); // El array vacío asegura que este efecto solo se ejecute una vez, similar a componentDidMount

  return (
    <FixedSizeList
      height={500}
      width={'100%'}
      itemCount={productosConStock.length}
      itemSize={100}
      itemData={productosConStock}
    >
      {Row}
    </FixedSizeList>
  );
};

export default ProductosTabla;

function Row(props) {
  const { agregarProductoFactura } = useContext(FacturaContext);

  const classess = useStyles();
  const { index, style, data } = props;

  const item = data[index];

  const rowStyles = {
    margin: 0,
    padding: 0,
    border: 'none',
    background: 'none',
    color: 'red',
    fontSize: '16px',
    position: 'relative',
    ...style
  };

  return (
    <div style={rowStyles}>
      <div key={item?.id}>
        <Paper
          id="productoTabla"
          style={{
            cursor: 'pointer',
            fontSize: '12px',
            fontFamily: 'Roboto',
            textAlign: 'justify'
          }}
          className={classess.paper}
          onClick={() => agregarProductoFactura(item)}
        >
          <Grid container wrap="nowrap" spacing={1}>
            <Grid item>
              <Avatar title={'Stock ' + item?.stock}>{item?.stock}</Avatar>
            </Grid>
            <Grid item xs>
              <strong style={{ color: '#3f51b5' }}>{item?.nombre}</strong>
              <br></br>
              {item.descripcion}
            </Grid>
            <Grid item xs={3}>
              P. Público ${item?.precio_publico}
              {/* <br />
              P. Técnico ${item?.precio_tecnico}
              <br />
              P. Mayorista ${item.precio_distribuidor} */}
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ fontSize: '9px' }}>
            Cod: {item?.codigo_barra}
          </Grid>
        </Paper>
      </div>
    </div>
  );
}
