import React from 'react';

const Logo = (props) => {
  return (
    <h1
      style={{
        color: 'white',
        fontFamily: 'FontName, sans-serif',
        fontSize: '2em',
        textTransform: 'uppercase',
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'
      }}
    >
      QUEVEDMARKET
    </h1>

    // <h1
    //   style={{
    //     color: 'white',
    //     fontFamily: 'FontName, sans-serif',
    //     fontSize: '2em',
    //     textTransform: 'uppercase'
    //   }}
    // >
    //   QUEVEDMARKET
    // </h1>

    // <img
    //   style={{
    //     width: '90%',
    //     height: '60px',
    //     margin: '0',
    //     padding: '0',
    //     borderRadius: '10px'
    //   }}
    //   alt="Logo"
    //   // src="https://quevedo2023.s3.us-east-2.amazonaws.com/compustart.png"
    //   src="https://static.vecteezy.com/system/resources/previews/005/548/394/non_2x/shop-store-market-with-pin-map-location-logo-icon-illustration-design-vector.jpg"
    //   {...props}
    // />
  );
};

export default Logo;
