import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { FacturaContext } from '../../context/FacturaContext';
import RowFactura from './FacturaRow_imp';
import TotalesFac from './TotalesFac';
import logo from '../../assets/Factura.PNG';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3)
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(0)
  },
  divider: {
    margin: theme.spacing(1, 0)
  }
}));

const Factura_imp = React.forwardRef((props, ref) => {
  const classes = useStyles();

  const { dataFactura } = props;
  console.log('dataFactura', dataFactura);

  useEffect(() => {
    // Aqui funcion
  }, []);
  // console.log('----------------------------', dataFactura.factura);
  // return <h1 ref={ref}>Esto quiero imprimir {dataFactura.factura.fecha}</h1>;
  var factura = dataFactura.factura;
  return (
    <div
      ref={ref}
      style={{ width: '94%', marginLeft: '19px', fontFamily: 'Arial' }}
    >
      <h1
        style={{
          color: 'black',
          textAlign: 'center',
          fontFamily: 'FontName, sans-serif',
          fontSize: '1.4em',
          textTransform: 'uppercase',
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'
        }}
      >
        QUEVEDMARKET
      </h1>

      <Typography variant="subtitle1" gutterBottom>
        <center>
          N° Control {factura?.id} {factura?.es_credito && ' (credito)'}
        </center>
      </Typography>

      <Grid container spacing={0} style={{ fontSize: '10px' }}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div>
              <strong> CI:</strong> {factura?.cliente?.cedula}
            </div>
            <div>
              <strong> Cliente:</strong> {factura?.cliente?.nombres}
            </div>
            <div>
              <strong> Telf.:</strong> {factura?.cliente?.telefono}
            </div>
            <div>
              <strong> Dir.:</strong> {factura?.cliente?.direccion}
            </div>
            <div>
              <strong> Fecha:</strong> {factura?.fecha}
            </div>

            <div>
              <strong> Despachado por:</strong>
              {factura?.nombreUsuario}
            </div>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={0} style={{ fontSize: '10px' }}>
        <Grid item xs={1}>
          <Paper className={classes.paper}>Cnt.</Paper>
        </Grid>
        <Grid item xs={7}>
          <Paper className={classes.paper}>Nombre producto</Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}>P. Unit.</Paper>
        </Grid>

        <Grid item xs={2}>
          <Paper className={classes.paper}>Total</Paper>
        </Grid>
      </Grid>

      <div>
        {factura?.detalles?.map((producto) => {
          return (
            <RowFactura
              key={producto?.id + producto?.tipoPrecio}
              producto={producto}
            />
          );
        })}
      </div>

      <TotalesFac key={1} totales={factura?.totales} />
    </div>
  );
});

export default Factura_imp;
