import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import BotonGuardarFactura from '../../../components/BotonGuardarFactura';
import TextField from '@material-ui/core/TextField';
import { FacturaContext } from '../../../context/FacturaContext';

import NumberFormatCustom from '../../../components/ValidationCurrency/ValidationCurrency';

import {
  formatCurrency
  // formatCurrencySimple3
} from '../../../Environment/utileria';

import FormasPago from './FormasPago';
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      border: 'none', // Puedes agregar estilos adicionales aquí según tus necesidades
      with: '30px',
      margin: '0',
      padding: '0',
      // backgroundColor: 'red',
      height: '10px',
      fontSize: '13px',
      textAlign: 'center'
    },
    '& .MuiInputLabel-root': {}

    // Agrega más estilos según sea necesario
  },

  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(0),
    fontSize: '12px'
  },
  paper: {
    padding: theme.spacing(0.3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(0.5)
  },
  paperRight: {
    padding: theme.spacing(1),
    textAlign: 'Right',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1)
  },
  divider: {
    margin: theme.spacing(2, 0)
  }
}));

export default function RowFactura({ totales, totalesFactura }) {
  const classes = useStyles();

  const {
    vuelto,
    setVuelto,
    billete,
    setBillete,

    observacion,
    setObservacion,
    formasPago,
    setFormasPago,
    esProforma
  } = useContext(FacturaContext);

  const ejecutarFuncion = () => {
    alert('sd');
    setBillete('123');
  };
  const calcularVuelto = (valor) => {
    if (!totalesFactura.varlorTotal > 0) return;

    setBillete(valor);
    // Aquí asumo que NumberFormatCustom retorna el valor numérico
    const valorBilleteNumerico = valor;
    const vueltoCalculado = valorBilleteNumerico - totalesFactura.varlorTotal;
    // Hacer algo con el vueltoCalculado, por ejemplo, mostrarlo o almacenarlo en el estado

    if (vueltoCalculado <= 0) {
      setVuelto(0);
    } else setVuelto(vueltoCalculado);
    console.log('Vuelto calculado:', vueltoCalculado);
    // setVueltoCalculado(vueltoCalculado);
  };

  const asignarObs = (e) => {
    setObservacion(e.target.value);
  };

  return (
    <div>
      <Divider className={classes.divider} />

      <div className={classes.container}>
        <div style={{ gridColumnEnd: 'span 8', height: '15px' }}>
          <Paper className={classes.paperRight}>Subtotal Sin Impuestos: </Paper>
        </div>
        <div style={{ gridColumnEnd: 'span 4' }}>
          <Paper className={classes.paper}>
            {formatCurrency(totalesFactura.subtotalsinimpuestos)}
          </Paper>
        </div>

        <div style={{ gridColumnEnd: 'span 8', height: '15px' }}>
          <Paper className={classes.paperRight}>Descuento : </Paper>
        </div>
        <div style={{ gridColumnEnd: 'span 4' }}>
          <Paper className={classes.paper}>{formatCurrency(0)}</Paper>
        </div>

        <div style={{ gridColumnEnd: 'span 8', height: '15px' }}>
          <Paper className={classes.paperRight}>Subtotal 0% : </Paper>
        </div>
        <div style={{ gridColumnEnd: 'span 4' }}>
          <Paper className={classes.paper}>
            {formatCurrency(totalesFactura.subtotal0)}
          </Paper>
        </div>

        <div style={{ gridColumnEnd: 'span 8', height: '15px' }}>
          <Paper className={classes.paperRight}>Subtotal 12% : </Paper>
        </div>
        <div style={{ gridColumnEnd: 'span 4' }}>
          <Paper className={classes.paper}>
            {formatCurrency(totalesFactura.subtotal12)}
          </Paper>
        </div>

        <div style={{ gridColumnEnd: 'span 8', height: '15px' }}>
          <Paper className={classes.paperRight}>iva 12% : </Paper>
        </div>
        <div style={{ gridColumnEnd: 'span 4' }}>
          <Paper className={classes.paper}>
            {formatCurrency(totalesFactura.iva12)}
          </Paper>
        </div>

        <div style={{ gridColumnEnd: 'span 8' }}>
          <Paper className={classes.paperRight}>Valor Total: </Paper>
        </div>
        <div style={{ gridColumnEnd: 'span 4' }}>
          <Paper
            className={classes.paper}
            style={{ fontWeight: 'bold', fontSize: '14px' }}
          >
            {totalesFactura.varlorTotal > 0.01 && !esProforma ? (
              <FormasPago
                formasPago={formasPago}
                setFormasPago={setFormasPago}
                TotalFactura={`${formatCurrency(totalesFactura.varlorTotal)}`}
              ></FormasPago>
            ) : (
              `$ ${totalesFactura.varlorTotal.toFixed(4)}`
            )}
          </Paper>
        </div>

        <div style={{ gridColumnEnd: 'span 5', height: '15px' }}>
          Billete: &#160;&#160;&#160;&#160;&#160;&#160;
          <TextField
            autoFocus
            margin="dense"
            disabled={totalesFactura.varlorTotal > 0 ? false : true}
            id="name"
            value={billete}
            onChange={(e) => calcularVuelto(e.target.value)}
            InputProps={{
              inputComponent: NumberFormatCustom
            }}
            style={{ textAlign: 'center' }}
            classes={classes}
          />
        </div>
        <div
          style={{ gridColumnEnd: 'span 3', fontSize: '14px' }}
          onClick={ejecutarFuncion}
        >
          VUELTO:
          <strong> {formatCurrency(vuelto)}</strong>
        </div>

        <div style={{ gridColumnEnd: 'span 9' }}>
          <Paper className={classes.paperRight} style={{ textAlign: 'left' }}>
            <TextareaAutosize
              value={observacion}
              defaultValue={observacion}
              onChange={asignarObs}
              style={{ width: '100%' }}
              aria-label="minimum height"
              rows={3}
              placeholder="Observaciones"
            />
          </Paper>
        </div>

        <div style={{ gridColumnEnd: 'span 3', textAlign: 'center' }}>
          <BotonGuardarFactura />
        </div>
      </div>
    </div>
  );
  // Formula anterior.
  // return (
  //   <div>
  //     <Divider className={classes.divider} />

  //     <div className={classes.container}>
  //       <div style={{ gridColumnEnd: 'span 8', height: '24px' }}>
  //         <Paper className={classes.paperRight}>SUBTOTAL: </Paper>
  //       </div>
  //       <div style={{ gridColumnEnd: 'span 4' }}>
  //         <Paper className={classes.paper}>
  //           {formatCurrency(totales.subtotal)}
  //         </Paper>
  //       </div>

  //       <div style={{ gridColumnEnd: 'span 8' }}>
  //         <Paper className={classes.paperRight}>IVA 12%: </Paper>
  //       </div>
  //       <div style={{ gridColumnEnd: 'span 4' }}>
  //         <Paper className={classes.paper}>{formatCurrency(totales.iva)}</Paper>
  //       </div>

  //       <div style={{ gridColumnEnd: 'span 8' }}>
  //         <Paper className={classes.paperRight}>TOTAL: </Paper>
  //       </div>
  //       <div style={{ gridColumnEnd: 'span 4' }}>
  //         <Paper
  //           className={classes.paper}
  //           style={{ fontWeight: 'bold', fontSize: '14px' }}
  //         >
  //           {totales.total > 0 && !esProforma ? (
  //             <FormasPago
  //               formasPago={formasPago}
  //               setFormasPago={setFormasPago}
  //               TotalFactura={`${formatCurrency(totales.total)}`}
  //             ></FormasPago>
  //           ) : (
  //             `$ ${totales.total}`
  //           )}
  //         </Paper>
  //       </div>

  //       <div style={{ gridColumnEnd: 'span 9' }}>
  //         <Paper className={classes.paperRight} style={{ textAlign: 'left' }}>
  //           <TextareaAutosize
  //             value={observacion}
  //             defaultValue={observacion}
  //             onChange={asignarObs}
  //             style={{ width: '100%' }}
  //             aria-label="minimum height"
  //             rows={3}
  //             placeholder="Observaciones"
  //           />
  //         </Paper>
  //       </div>

  //       <div style={{ gridColumnEnd: 'span 3', textAlign: 'center' }}>
  //         <BotonGuardarFactura />
  //       </div>
  //     </div>
  //   </div>
  // );
}
